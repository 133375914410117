import paginate from "@/components/paginate.vue";
import { URL } from "@/api/gov_invoices_history.js";
import { URL as COMPANY_URL } from "@/api/company";
import { mapGetters } from "vuex";
import formatDate from "@/plugins/formatDate.js";
import formatTime from "@/plugins/formatTime.js";
import { formatDateStandard } from "../../../plugins/formatDate";
import UserIcon from "@/components/UserIcon.vue";
import { TIME_INTERVAL } from "@/consts";

export default {
  components: {
    paginate,
    UserIcon,
  },
  data() {
    return {
      shortcuts: [
        {
          text: "1 tuần trước",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "2 tuần trước",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
            return [start, end];
          },
        },
        {
          text: "1 tháng trước",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
      ],
      invoiceType: "buy",
      total_sell: 0,
      total_buy: 0,
      company: {},
      is_syncable: true,
      gov_count: 0,
      gov_max: 0,
      paginate: {
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      detailPaginate: {
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      invoice_histories: [],
      showSyncModal: false,
      showDetailDialog: false,
      govInvoiceHistorySelected: {},
      syncRequest: {
        date: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        })(),
        invoice_type: true, // [false, true] = [buy, sell]
      },
      idInterval: null,
      isRequesting: false,
      isLoading: false,
      isDetailLoading: false,
      sync_request_threshold: false,
      gov_invoices_history_details: []
    };
  },

  created() {
    this.getGovInvoicesSummary();
    this.getGovInvoices();
  },

  methods: {
    disabledDate(time) {
      let restricted = time.getTime() > Date.now();
      if (this.company && this.company.ngayCap && this.isDate(this.company.ngayCap)) {
        restricted = restricted || (time.getTime() < new Date(this.company.ngayCap));
      }
      return restricted;
    },
    handleRequestSyncDate(dateRange) {
      const start = new Date(dateRange[0]);
      const end = new Date(dateRange[1]);
      const maximum_range_in_days = this.getUserRole == 'ITaxAdmin' ? 186 : 30;
      if (end.getTime() - start.getTime() > 3600 * 1000 * 24 * maximum_range_in_days) {
        start.setTime(end.getTime() - 3600 * 1000 * 24 * maximum_range_in_days);
        this.syncRequest.date = [start, end];
      }
    },
    async getDetailCompany(id_company) {
      const result = await this.$request({
        url: COMPANY_URL.EDIT_DETAIL_COMPANY.replace(":id", id_company),
      });

      const res = result.data;
      if (res.code == 200) {
        this.$store.dispatch("setTargetCompany", res.data);
      }
    },
    isDate(date) {
      return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    },
    async getGovInvoicesSummary() {
      let params = {};
      params.company_id = this.selectCurrentCompany;

      const result = await this.$request({
        url: URL.GOV_INVOICES_HISTORY_SUMMARY,
        params,
      });
      const data = result.data.data;
      let currency = new Intl.NumberFormat('de-DE')
      this.total_buy = data.total_buy == null ? 0 : data.total_buy;
      this.total_buy = currency.format(this.total_buy)
      this.total_sell = data.total_sell == null ? 0 : data.total_sell;
      this.total_sell = currency.format(this.total_sell)

      this.gov_count = data.gov_count == null ? 0 : data.gov_count;
      this.gov_max = data.gov_max == null ? 0 : data.gov_max;
      this.company = data.company == null ? {} : data.company;

      this.sync_request_threshold = data.sync_request_threshold

      if (data.is_syncable != null) {
        this.is_syncable = data.is_syncable;
      }
    },

    async getGovInvoicesDetail() {
      this.isDetailLoading = true;
      let params = {}
      params.page = this.detailPaginate.currentPage;
      params.limit = this.detailPaginate.limit;
      const res = await this.$request({
        url: URL.GOV_INVOICES_HISTORY_DETAIL.replace(
          ":id",
          this.govInvoiceHistorySelected.id
        ),
        isHideLoading: true,
        params
      });

      this.gov_invoices_history_details = res.data.data.data;
      this.detailPaginate.totalPage = res.data.data.total_pages;
      this.detailPaginate.totalItem = res.data.data.total;
      this.detailPaginate.limit = res.data.data.limit;

      this.isDetailLoading = false;
    },

    async handleRereadDetail(all=false) {
      this.$swal({
        title: "Đọc lại",
        html: `Xác nhận đọc lại ?`,
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.showDetailDialog = false
          await this.rereadGovInvoices(all);
          await this.getGovInvoices();
        }
      })
    },

    async rereadGovInvoices(all=false) {
      this.isDetailLoading = true;
      await this.$request({
        method: "POST",
        url: URL.REREAD_GOV_INVOICES_HISTORY.replace(
          ":id", this.govInvoiceHistorySelected.id
        ),
        data: {all},
        isHideLoading: true
      });
      this.isDetailLoading = false;
    },

    indexMethod(index) {
      return index + 1 + (this.detailPaginate.currentPage - 1) * this.detailPaginate.limit;
    },

    indexMethodMain(index) {
      return index + 1 + (this.paginate.currentPage - 1) * this.paginate.limit;
    },

    async getGovInvoices(isShowLoading=true) {
      if (isShowLoading) {
        this.isLoading = true;
      }
      let params = {};
      params.company_id = this.selectCurrentCompany;
      params.invoice_type = this.invoiceType;
      params.page = this.paginate.currentPage;
      params.limit = this.paginate.limit;

      const res = await this.$request({
        url: URL.LIST_GOV_INVOICES_HISTORY,
        isHideLoading: true,
        params,
      });
      this.paginate.totalPage = res.data.data.total_pages;
      this.paginate.totalItem = res.data.data.total;
      this.paginate.limit = res.data.data.limit;
      this.$store.dispatch("setGovInvoiceHistoryPaginate", this.paginate);
      this.isLoading = false;

      const data = res.data.data.data;
      this.invoice_histories = data;
      if (this.govInvoiceHistorySelected && this.govInvoiceHistorySelected.id) {
        await this.getGovInvoicesDetail();
      }

      this.handleReload();
    },

    handleReload(immediately=false) {
      const isUpdating = this.invoice_histories
        .some((item) => item.total_pending > 0 || item.request_status == 0)

      clearInterval(this.idInterval);
      const retryFuction = () => {
        this.getGovInvoices(false);
        this.getGovInvoicesSummary();
      }
      if (immediately) {
        retryFuction()
      }

      if (isUpdating) {
        this.idInterval = setInterval(retryFuction, TIME_INTERVAL);
      }
    },

    async handleSyncRequest() {
      this.isRequesting = true;
      const data = {
        company_id: this.selectCurrentCompany,
        start_date: formatDateStandard(this.syncRequest.date[0]),
        end_date: formatDateStandard(this.syncRequest.date[1]),
        invoice_type: this.syncRequest.invoice_type ? "sell" : "buy",
      };
      let res = await this.$request({
        method: "POST",
        url: URL.SYNC_GOV_INVOICES_HISTORY,
        isHideLoading: true,
        data,
      });

      res = res.data;
      this.showSyncModal = false;
      this.isRequesting = false;
      this.getDetailCompany(this.selectCurrentCompany);
      this.handleShowAlert(res);
    },
    handleShowAlert(res) {
      if (res.code == 200) {
        this.$swal({
          title: "Yêu cầu thành công",
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
        this.invoiceType = this.syncRequest.invoice_type ? "sell" : "buy";
        this.getGovInvoices();
        this.getGovInvoicesSummary();
      } else if (res.code == 400) {
        this.$swal({
          title: "Yêu cầu thất bại",
          text: res.errors[0]["message"],
          icon: "error",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      } else {
        this.$swal({
          title: "Exception",
          text: "Vui lòng liên hệ admin để được giải quyết",
          icon: "error",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }
    },

    async renew_password() {
      this.$swal({
        title: "Gửi yêu cầu gia hạn mật khẩu",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isRequesting = true;
          const data = { company_id: this.selectCurrentCompany };
          let res = await this.$request({
            method: "PUT",
            url: URL.SYNC_GOV_INVOICES_HISTORY,
            isHideLoading: true,
            data,
          });
          res = res.data;
          this.handleShowAlert(res);
          this.showSyncModal = false;
          this.isRequesting = false;
          this.getDetailCompany(this.selectCurrentCompany);
        }
      })
    },

    handleCurrentPageChange(page) {
      this.paginate.currentPage = page;
      this.getGovInvoices();
    },

    handleLimitChange(limit) {
      this.paginate.limit = limit;
      this.paginate.currentPage = 1;
      this.getGovInvoices();
    },

    handleCurrentPageChangeDetail(page) {
      this.detailPaginate.currentPage = page;
      this.getGovInvoicesDetail();
    },

    handleLimitChangeDetail(limit) {
      this.detailPaginate.limit = limit;
      this.detailPaginate.currentPage = 1;
      this.getGovInvoicesDetail();
    },

    handleTabSelected() {
      this.paginate = {
        ...this.paginate,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      };
      this.getGovInvoicesSummary();
      this.getGovInvoices();
    },
    async clickRow(govInvoiceHistory) {
      this.showDetailDialog = true;
      this.govInvoiceHistorySelected = govInvoiceHistory;
      this.detailPaginate = {
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      }
      await this.getGovInvoicesDetail();
    },
    handleClickInvoice(invoice) {
      let route = this.$router.resolve({
        name: "editInvoices",
        params: { invoiceId: invoice.id },
      });
      window.open(route.href, "_blank");
    },
    handleRowUpdating({ row }) {
      return row.is_updating ? "warning-row" : "success-row";
    },
    async handleReread(id) {
      const data = { id };
      this.$request({
        url: URL.REREAD_GOV_INVOICES_HISTORY_DETAIL,
        isHideLoading: true,
        method: "POST",
        data,
      });
      let gov_invoices_history_details =
        await this.gov_invoices_history_details.map(
          (item) => {
            if (item.id == id) {
              item.status = 0;
              item.invoice_status = 0;
            }
            return item;
          }
        );
      this.gov_invoices_history_details = gov_invoices_history_details
    },
    handleGuide() {
      this.$swal({
        title: "Hướng dẫn khôi phục",
        html: `<ul class="text-left text-sm">
<li>1. Đăng nhập <b>thành công</b> tại trang web <b><a target="_blank" href="https://hoadondientu.gdt.gov.vn">hoadondientu.gdt.gov.vn</a></b></li>
<li>2. Kiểm tra/cập nhật mật khẩu đã chính xác tại <b>iTax</b>
<li>3. Sau khi đăng nhập thành công, hãy chọn nút <b>"Đã đăng nhập thành công"</b></li>
</ul>
`,
        showCancelButton: true,
        confirmButtonText: "Đã đăng nhập thành công",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Xác nhận đăng nhập thành công",
            html: `Hãy đảm bảo đăng nhập thành công để tránh việc bị giới hạn sử dụng tài khoản !!!`,
            showCancelButton: true,
            confirmButtonText: "Xác nhận",
            cancelButtonText: "Hủy",
            confirmButtonColor: "#3b82f6",
            cancelButtonColor: "#adadad",
            reverseButtons: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              const data = { reset_gov: true };
              await this.$request({
                url: COMPANY_URL.EDIT_DETAIL_COMPANY.replace(
                  ":id",
                  this.getTargetCompany.id
                ),
                method: "PUT",
                data,
              });
              this.getGovInvoicesSummary();
            }
          });
        }
      });
    },
    arraySpanMethod({ row, columnIndex }) {
      if (row.status == 3) {
        if (columnIndex === 7) {
          return [1, 3];
        }
      }
    },

    ableToRenewPassword(expired_at) {
      if (expired_at) {
        let currentTime = new Date();
        // Reset pass 2 weeks before expired
        currentTime.setDate(currentTime.getDate() + 14)
        return new Date(expired_at) < currentTime
      }

      return false
    },
    formatDate,
    formatTime,
  },

  computed: {
    ...mapGetters([
      "selectCurrentCompany",
      "getGovInvoiceHistoryPaginate",
      "getTargetCompany",
      "getUserRole",
      "getWindowFocusState"
    ]),
  },

  watch: {
    selectCurrentCompany() {
      this.paginate = {
        ...this.paginate,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      };
      this.getGovInvoicesSummary();
      this.getGovInvoices();
    },
    getWindowFocusState(state) {
      if (state) {
        this.handleReload(true);
      } else {
        clearInterval(this.idInterval);
      }
    }
  },
  beforeRouteLeave() {
    clearInterval(this.idInterval);
  },
};
